<template>
    <div class="contenedor-grafica">


        <nav class="tipos" v-if="data.agrupaciones">
            <a v-for="(el, key) in data.agrupaciones" :key="key">{{ el }}</a>
        </nav>

        <div id="chartdiv"></div>


    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";

export default {
    name: 'grafica-columnas',
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartdiv");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout
            }));

            //root.numberFormatter.set("numberFormat", "####");
            root.locale = am5locales_es_ES;

            var data = new Array();

            // cambio 2024-11-13 no muestra el año si no tiene datos en Real
            // this.data.Series.forEach(e => {

            //     if (e.category == 'Real' || (e.category == 'Madrid' && e.Oculto == false)) {
            //         let el = {
            //             year: e.name.toString(),
            //             value: e.value,
            //             idindicador: e.IdIndicador,
            //             //meta: '',
            //         };

            //         data.push(el);

            //     } else if (e.category == 'Meta') {
            //         data.forEach(d => {
            //             if (d.year == e.name) {
            //                 d.meta = e.value;
            //             }
            //         })
            //     }
            // });

            this.data.Series.forEach(e => {
                let existingElement = data.find(d => d.year === e.name.toString());

                if (!existingElement) {
                    // Crear el objeto de datos si no existe
                    let el = {
                        year: e.name.toString(),
                        value: e.category === 'Real' ? e.value : null,
                        meta: e.category === 'Meta' ? e.value : null,
                        idindicador: e.IdIndicador
                    };
                    data.push(el);
                } else {
                    // Si el objeto ya existe, agregar el valor
                    if (e.category === 'Real') {
                        existingElement.value = e.value;
                    } else if (e.category === 'Meta') {
                        existingElement.meta = e.value;
                    }
                }
            });


            var xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "year",
                    renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 30 }),
                    tooltip: am5.Tooltip.new(root, {})
                })
            );

            xAxis.data.setAll(data);


            let escalaCeroDiez = this.data.EscalaCeroDiez;

            var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: (data.some(e => e.value < 0)) ? -20 : 0,
                    max: (escalaCeroDiez) ? 9 : null,
                    extraMax: 0.1,
                    renderer: am5xy.AxisRendererY.new(root, {

                    })
                })
            );

            var series1 = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Valor",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "year",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{name} en {categoryX}: {valueY} {info}"
                    })
                })
            );

            series1.columns.template.setAll({
                tooltipY: am5.percent(10),
                templateField: "columnSettings"
            });

            series1.data.setAll(data);

            var series2 = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "Meta",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "meta",
                    categoryXField: "year",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{name} en {categoryX}: {valueY} {info}"
                    })
                })
            );

            series2.strokes.template.setAll({
                strokeWidth: 3,
                templateField: "strokeSettings"
            });


            series2.data.setAll(data);

            series2.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        strokeWidth: 3,
                        stroke: series2.get("stroke"),
                        radius: 5,
                        fill: root.interfaceColors.get("background")
                    })
                });
            });

            chart.set("cursor", am5xy.XYCursor.new(root, {}));


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);
            series1.appear();


        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica #chartdiv {
    width: 100%;
}
</style>